<template>
    <div class="pa-10">
      <vue-markdown>
        {{this.termsOfUse}}
      </vue-markdown>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import VueMarkdown from "vue-markdown";

    export default {
        name      : "base-terms-of-use-page-component",
        props     : {},
        model: {
            event: 'updateModel'
        },
        components: {VueMarkdown},
        data()      {
            return {}
        },
        computed  : {
          ...mapGetters('siteContentsStore', {
            termsOfUse: 'getTermsOfUse'
          })
        },
        methods   : {},
        watch     : {}
    }
</script>

<style scoped>

</style>